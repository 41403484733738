.nav {
  padding: 10px;
  margin-bottom: 20;
  text-decoration: underline;
  cursor: pointer;
}

.FormContainer {
  margin: 25px 20px 100px 60px;
  display: flex;
}      

.Input {
  width: 250px; 
  color: #606060;
}

.form-control:focus, .Input:focus {
  color: #606060
}

.Label {
  margin-bottom: 0.3rem;
  font-weight: 500;
}

.AgentPopupLink {
  margin-top: 20px;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  font-size: large;
}