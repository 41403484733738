.centerDiv{
  width: 100%;
  height: 97vh;
}
.centerTextTitle{
  position: relative;
  padding: 15px;
}
.centerTextTitle2{
  padding: 5px;
}