.buttonWrapper {
  margin: 0 auto;
  width: 70%;
  padding-top: 5px;
}

.inputWrapper input {
  width: 70%;
  border: none;
  margin: 24px 0 0;
  font-size: 16px;
  line-height: 22px;
  border-bottom: 1px solid #9e9e9e;
}

.inputWrapper input:focus {
  outline: none;
}