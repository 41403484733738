.buttonWrapper {
  margin: 0 auto;
  width: 70%;
  padding-top: 5px;
}

.Button {
  width: 100%;
  background-color: #4A7CB7;
  border: #4A7CB7 solid 1px;
}

.Button:disabled {
  background-color: #4170a5;
  border: #4170a5 solid 1px;
}

.Button:hover, .Button:active, .Button:focus {
  background-color: #4170a5;
  border: #4170a5 solid 1px;
}

.idgoButton {
  padding-left: 10px;
}