
.container {
  width: 95%;
  margin: auto;
  display: flex;
  justify-content: center;
  background-color: #e8ecf3;
}
.center {
  text-align: center;
}

.loginBox {
  fill: #ffffff;
  width: 380px;
  height: 480px;
  margin: 25px;
  background-color: rgb(255, 252, 252);
  box-shadow: 0px 10px 50px rgb(0 0 0 / 15%);
  border-radius: 10px;
}

.loginHeader {
  margin: auto;
  width: 100%;
  text-align: center;
  color: #606060;
  font-size: 30px;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 5px;
}
.loginHeader2 {
  margin: auto;
  width: 100%;
  text-align: center;
  color: #606060;
  font-size: 13px;
  font-weight: 500;
  padding-bottom: 5px;
  border-bottom: 2px solid rgb(147, 146, 146);
}

.orLoginWith {
  color: #555555;
  display: block;
  font-size: 14px;
  text-align: center;
  padding-top: 10px;
}

.buttonWrapper {
  margin: 0 auto;
  width: 70%;
  padding-top: 5px;
}

.inputWrapper input {
  width: 70%;
  border: none;
  margin: 24px 0 0;
  font-size: 16px;
  line-height: 22px;
  border-bottom: 1px solid #9e9e9e;
}
.inputWrapper input:focus {
  outline: none;
}

input::placeholder {
  color:#cfcece;
}

div.forgotPassword {
  margin: 0 auto;
  width: 70%;
  padding: 8px 0 30px 0;
}
.forgotPassword div {
  color: #d02129;
  font-size: 15px;
  line-height: 16px;
  float: right;
  cursor: pointer;
}

.idgoButton {
  padding-left: 10px;
}

.Button {
  width: 100%;
  background-color: #4A7CB7;
  border: #4A7CB7 solid 1px;
}

.Button:disabled {
  background-color: #4170a5;
  border: #4170a5 solid 1px;
}

.Button:hover, .Button:active, .Button:focus {
  background-color: #4170a5;
  border: #4170a5 solid 1px;
}