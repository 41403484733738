.FormContainer {
  margin: 25px 20px 100px 60px;
  display: flex;
}      

.Input {
  width: 250px; 
  color: #606060;
}

.form-control:focus, .Input:focus {
  color: #606060
}

.Label {
  margin-bottom: 0.3rem;
  font-weight: 500;
}

.Button {
  height: max-content;
  margin: 0 0 -4.5rem -251px;
  align-self: flex-end;
  width: 251px;
  background-color: #4A7CB7;
  border: #4A7CB7 solid 1px;
}

.Button:disabled {
  background-color: #4170a5;
  border: #4170a5 solid 1px;
}

.Button:hover, .Button:active, .Button:focus {
  background-color: #4170a5;
  border: #4170a5 solid 1px;
}