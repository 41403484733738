.backButton {
  width: 32px;
  float: left;
  cursor: pointer;
}

.loginHeader {
  margin: auto;
  width: 100%;
  text-align: center;
  color: #606060;
  font-size: 30px;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 5px;
}

.loginHeader2 {
  margin: auto;
  width: 100%;
  text-align: center;
  color: #606060;
  font-size: 13px;
  font-weight: 500;
  padding-bottom: 5px;
  border-bottom: 2px solid rgb(147, 146, 146);
}

.orLoginWith {
  color: #555555;
  display: block;
  font-size: 14px;
  text-align: center;
  padding-top: 10px;
}

.container {
  width: 95%;
  margin: auto;
  display: flex;
  justify-content: center;
  background-color: #e8ecf3;
  height: 97vh;
}

.center {
  text-align: center;
}

.loginBox {
  fill: #ffffff;
  width: 380px;
  height: 480px;
  margin: 25px;
  background-color: rgb(255, 252, 252);
  box-shadow: 0px 10px 50px rgb(0 0 0 / 15%);
  border-radius: 10px;
}