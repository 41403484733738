.hostPage {
  background-color: rgb(181, 246, 224);
}
.nav {
  padding: 10px;
  margin-bottom: 20;
  text-decoration: underline;
  cursor: pointer;
}

.auth_pass {
  color: rgb(60, 198, 60);
  font-size: large;
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
}

.auth_data_label {
  margin-left: 10px;
}
.auth_data_value {
  font-weight: bold;
  margin-left: 10px;
}