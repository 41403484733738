.box {
  width: 100%;
  max-width: 900px;
  padding: 15px;
}
.box h1 {
  margin: 10px 0;
  color: #4A7CB7;
  font-size: 2rem;
  letter-spacing: 2px;
  text-align: center;
}
.cards {
  display: inline-flex;
  flex-wrap: wrap;
}
.card {
  cursor: pointer;
  margin: 6px;
  width: 200px;
  padding: 15px 10px;
  background-color: #fff;
  border-radius: 3px;
  transition: transform .3s;
}
.cardTitle {
  margin: 5px 0;
  color: #285283;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
}
.cardExcerpt {
  font-size: .75rem;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 4px;
}
.cardExcerpt b {
  font-size: .6rem;
  color: rgb(58, 182, 60);
}
.cardExcerpt span {
  color: rgb(62, 68, 62);
  font-weight: 600;
}
.card:hover {
  box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, .2);
	transform: translateY(-4px);
}
.cardThumb {
  width: 100%;
  text-align: center;
}