
.container {
  display: flex;
}
.center {
  margin: 0 auto;
  width: 98%;
}
.loginHeader {
  margin-top : 300px;
}

.pageHeader {
  display: grid;
  grid-template-areas: 'header info idgo avatar';
  grid-template-columns: 1fr auto;
  gap: 5px;
  margin: 10px 0;
  align-items: center
}
.userInfo {
  grid-area: info;
  font-size: 14px;
  font-family: "Arial Narrow","Segoe UI",system-ui,"Open Sans","Helvetica Neue",sans-serif;
}
.userEmail {
  text-align: right;
}
.userMobileNumber {
  text-align: right;
}
.userIdgo {
  grid-area: idgo;
  width: 24px;
  height: 34px;
}
.userAvatar {
  grid-area: avatar;
  height: 28px;
  width: 28px;
  border-radius: 28px;
  cursor: pointer;
}

.accountsHeader {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 10px;
}
.accountsList {
  margin: auto;
  width: 95%;
  border: 1px solid gray;
  border-radius: 5px;
}

.accountRow {
  display: grid;
  grid-template-areas:
    'icon name name'
    'icon label balance';
  grid-template-columns: 40px auto auto;
  background-color: white;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid gray;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 8px;
}
.accountRow:first-child {
  border-radius: 5px 5px 0 0;
}
.accountRow:last-child {
  border-radius: 0 0 5px 5px;
}
.accountRow:hover {
  background-color: #ccc; 
}
.accountIcon {
  grid-area: icon;
}
.accountIconImg {
  width: 30px;
  height: 30px;
}
.accountName {
  grid-area: name;
  font-size: 12px;
  text-align: left;
  width: 180px;
}
.accountLabel {
  grid-area: label;
  font-size: 10px;
}
.accountBalance {
  grid-area: balance;
  font-size: 12px;
  font-weight: 700;
  text-align: right;
}

.moveMoneyHeader {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 0;
}
.moveMoneyList {
  width: 95%;
  margin: auto;
  display: grid;
  grid-template-areas:
    'transfers deposits payments';
  gap: 10px;
}
.moveMoneyImg {
  height: 22px;
}
.moveMoney {
  font-size: 12px;
  color: rgb(55, 158, 158);
  font-weight: 700;
  background-color: white;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid gray;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 8px;
  border: 2px solid gray;
  border-radius: 5px;
}
.moveMoney div {
  text-align: center;
}
button.moveMoney:hover {
  background-color: #ccc; 
}


.idgoEnrollHeader {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  padding: 20px 0 10px 0;
}
.idgoAutoEnroll {
  background-color: white;
  font-size: 12px;
  border: 2px solid rgb(142, 82, 82);
  border-radius: 5px;
  width: 70%;
  color: green;
  font-weight: 500;
}
.idgoAutoEnroll:hover {
  background-color: #ccc; 
}
.idgoAutoEnrollWrapper {
  width: 95%;
  display: flex;
  justify-content: center;
}


.EnrollButton {
  height: max-content;
  margin: 20px;
  align-self: flex-start;
  width: 180px;
  background-color: transparent;
  border: transparent;
  font-size: small;
  color: #4A7CB7;
  text-decoration: underline;
}

.EnrollButton:hover, .EnrollButton:active, .EnrollButton:focus {
  background-color: transparent;
  border: transparent;
  font-weight: 600;
  color:#4A7CB7;
}